$background-color: #3b3b3b;
$theme-color-1: #008cff;
$theme-color-2: #fff;
$font-color-1: #fff;
$font-color-2: #008cff;
$logo-font-color: #3b3b3b;
$select-font-color: #3b3b3b;
$select-border-color: #cacfd7;
$select-border-hover-color: #7bc4ff;
$select-border-active-color: #008cff;
$title-font-size: 2rem;
$text-font-size: 1.5rem;
$logo-font-size: 2rem;
$select-font-size: 1.5rem;
